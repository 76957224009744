*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: #e5e7eb; /* 2 */
}

::before,
::after {
  --tw-content: '';
}

html,
:host {
  line-height: 1.5; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */ /* 3 */
  tab-size: 4; /* 3 */
  font-family: ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; /* 4 */
  font-feature-settings: normal; /* 5 */
  font-variation-settings: normal; /* 6 */
  -webkit-tap-highlight-color: transparent; /* 7 */
}

body {
  margin: 0; /* 1 */
  line-height: inherit; /* 2 */
}

hr {
  height: 0; /* 1 */
  color: inherit; /* 2 */
  border-top-width: 1px; /* 3 */
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 20px;
  height: 20px;
  margin: 0 auto;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: #fff;
  animation: spinner 0.6s linear infinite;
}
.loading-spinner {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border-radius: 50%;
  border: 3px solid #2c3e50;
  border-top-color: #fff;
  animation: spinner 0.6s linear infinite;
}

.transcription-loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #233445;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  animation: transcriptionSpin 1s linear infinite;
  margin: 0 auto 1rem;
}

@keyframes transcriptionSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
